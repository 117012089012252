@import "~bootstrap/scss/bootstrap";

.card {
  // background-color: #ffe2ed;
  background-color: #ffeff5;
  padding: 5px;
  
  .card-img-top {
    align-self: center;
    border-radius: 50% !important;
    height: 150px;
    margin-top: 16px;
    width: 200px;
  }

  .btn-lg { 
    // background-color: #428ecc;
    background-color: #6aa9dc;
    border-color: #609aca;
  }

  h3 {
    font-size: 0.9rem;
    font-style: italic;
  }
  
  .mx-auto {
    padding: 16px;
  }
  
  .card-body {
    padding: 2rem 3rem 8rem;
  }
}

.card-footer{
  background-color: #ffeff5;
  display: inline-flex;
  justify-content: center;
}
